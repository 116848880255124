.raw-form {
  padding: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  & textarea {
    min-height: 50vh;
  }
  & .button-container {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    & button {
      margin-top: 1rem;
      flex: 3;
      margin-right: 0.2rem;
    }
    & select {
      margin-top: 1rem;
      flex: 1;
      margin-left: 0.2rem;
    }
  }
}

.clean-text-field {
  padding: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  & > textarea {
    min-height: 50vh;
  }
  & .button-container {
    margin-top: 1rem;
    display: flex;
    flex: 1;
    flex-direction: row;
  }
  & button {
    flex: 1;
    &:first-child {
      margin-right: 0.4rem;
    }
    &:nth-child(2) {
      margin-left: 0.4rem;
    }
  }
}

textarea {
  resize: none;
}
