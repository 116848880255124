@import "../node_modules/milligram/dist/milligram.min.css";
$breakpoint-medium: 800px;

body {
  margin: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

$default-padding: 1rem;

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.back-button {
  font-size: 2rem;
  padding: 0 2rem;
}

nav {
  &.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    & > * {
      flex: 1;
    }

    & ul {
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      & li {
        vertical-align: middle;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 2.2rem;
        @media screen and (max-width: $breakpoint-medium) {
          font-size: 2rem;
        }
      }
    }
  }
}

h1 {
  padding: 0 $default-padding;
}

h2,
h3,
h4 {
  padding: 0 $default-padding;
}

.App {
  display: flex;
  flex: 1;
  flex-direction: column;
  & p {
    padding: 0 $default-padding;
  }
}

.app-wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.deforest-description {
  padding: 8px;
}

small {
  padding: $default-padding;
}

.raw-text {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.clean-text {
  flex: 1;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
